<script setup lang="ts">

const route = useRoute()

const { t } = useI18n()

const navigation = inject<Ref<unknown>>('navigation', ref([]))

const links = computed(() => [
  {
    label: t('functions'),
    active: route.fullPath === '/#functions',
    to: '/#functions'
  },
  {
    label: t('pricing'),
    active: route.fullPath === '/#pricing',
    to: '/#pricing'
  },
  {
    label: t('supportedPlatforms'),
    active: route.fullPath === '/#supportedPlatforms',
    to: '/#supportedPlatforms'
  },
  {
    label: t('faq'),
    active: route.fullPath === '/#faqs',
    to: '/#faqs'
  }
])
</script>

<template>
  <UHeader :links="links">
    <template #logo>
      Journaledge
      <UBadge
        label="JL"
        variant="subtle"
        class="mb-0.5"
      />
    </template>

    <template #right>
      <UButton
        :label="$t('signIn')"
        color="gray"
        to="/login"
      />
      <UButton
        :label="$t('signUp')"
        icon="i-heroicons-arrow-right-20-solid"
        trailing
        color="black"
        to="/signup"
        class="hidden lg:flex"
      />
    </template>

    <template #panel>
      <UNavigationTree
        :links="mapContentNavigation(navigation as any)"
        default-open
      />
    </template>
  </UHeader>
</template>
