import { default as add_45tradeZ865MShyMNMeta } from "/app/pages/admin/add-trade.vue?macro=true";
import { default as inboxOPD5JSbqsKMeta } from "/app/pages/admin/inbox.vue?macro=true";
import { default as indexi8nCATZISJMeta } from "/app/pages/admin/index.vue?macro=true";
import { default as indexRS6tahnJHcMeta } from "/app/pages/admin/settings/index.vue?macro=true";
import { default as membersvvRXSH0vaiMeta } from "/app/pages/admin/settings/members.vue?macro=true";
import { default as notificationspydCJNuIfwMeta } from "/app/pages/admin/settings/notifications.vue?macro=true";
import { default as tradesc4PaCp5IW7Meta } from "/app/pages/admin/trades.vue?macro=true";
import { default as usersgSsTxNnO5UMeta } from "/app/pages/admin/users.vue?macro=true";
import { default as auth7tE1sZcZDUMeta } from "/app/pages/auth.vue?macro=true";
import { default as _91slug_93cdf2dv6FbPMeta } from "/app/pages/blog/[slug].vue?macro=true";
import { default as indexgWaMlevJ7RMeta } from "/app/pages/blog/index.vue?macro=true";
import { default as blogR6EtYuEVDlMeta } from "/app/pages/blog.vue?macro=true";
import { default as createProfilelTCL4xtPZjMeta } from "/app/pages/createProfile.vue?macro=true";
import { default as _91_46_46_46slug_93PMu0fmbT1nMeta } from "/app/pages/docs/[...slug].vue?macro=true";
import { default as docscbEptYMwcSMeta } from "/app/pages/docs.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as pick7jB5p0aBxZMeta } from "/app/pages/pick.vue?macro=true";
import { default as pricingpTmAIboEh0Meta } from "/app/pages/pricing.vue?macro=true";
import { default as signupezBbZGXKU6Meta } from "/app/pages/signup.vue?macro=true";
export default [
  {
    name: "admin-add-trade___cz",
    path: "/admin/add-trade",
    meta: add_45tradeZ865MShyMNMeta || {},
    component: () => import("/app/pages/admin/add-trade.vue").then(m => m.default || m)
  },
  {
    name: "admin-add-trade___en",
    path: "/en/admin/add-trade",
    meta: add_45tradeZ865MShyMNMeta || {},
    component: () => import("/app/pages/admin/add-trade.vue").then(m => m.default || m)
  },
  {
    name: "admin-inbox___cz",
    path: "/admin/inbox",
    meta: inboxOPD5JSbqsKMeta || {},
    component: () => import("/app/pages/admin/inbox.vue").then(m => m.default || m)
  },
  {
    name: "admin-inbox___en",
    path: "/en/admin/inbox",
    meta: inboxOPD5JSbqsKMeta || {},
    component: () => import("/app/pages/admin/inbox.vue").then(m => m.default || m)
  },
  {
    name: "admin___cz",
    path: "/admin",
    meta: indexi8nCATZISJMeta || {},
    component: () => import("/app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___en",
    path: "/en/admin",
    meta: indexi8nCATZISJMeta || {},
    component: () => import("/app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings___cz",
    path: "/admin/settings",
    component: () => import("/app/pages/admin/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings___en",
    path: "/en/admin/settings",
    component: () => import("/app/pages/admin/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-members___cz",
    path: "/admin/settings/members",
    component: () => import("/app/pages/admin/settings/members.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-members___en",
    path: "/en/admin/settings/members",
    component: () => import("/app/pages/admin/settings/members.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-notifications___cz",
    path: "/admin/settings/notifications",
    component: () => import("/app/pages/admin/settings/notifications.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-notifications___en",
    path: "/en/admin/settings/notifications",
    component: () => import("/app/pages/admin/settings/notifications.vue").then(m => m.default || m)
  },
  {
    name: "admin-trades___cz",
    path: "/admin/trades",
    meta: tradesc4PaCp5IW7Meta || {},
    component: () => import("/app/pages/admin/trades.vue").then(m => m.default || m)
  },
  {
    name: "admin-trades___en",
    path: "/en/admin/trades",
    meta: tradesc4PaCp5IW7Meta || {},
    component: () => import("/app/pages/admin/trades.vue").then(m => m.default || m)
  },
  {
    name: "admin-users___cz",
    path: "/admin/users",
    meta: usersgSsTxNnO5UMeta || {},
    component: () => import("/app/pages/admin/users.vue").then(m => m.default || m)
  },
  {
    name: "admin-users___en",
    path: "/en/admin/users",
    meta: usersgSsTxNnO5UMeta || {},
    component: () => import("/app/pages/admin/users.vue").then(m => m.default || m)
  },
  {
    name: "auth___cz",
    path: "/auth",
    meta: auth7tE1sZcZDUMeta || {},
    component: () => import("/app/pages/auth.vue").then(m => m.default || m)
  },
  {
    name: "auth___en",
    path: "/en/auth",
    meta: auth7tE1sZcZDUMeta || {},
    component: () => import("/app/pages/auth.vue").then(m => m.default || m)
  },
  {
    name: blogR6EtYuEVDlMeta?.name,
    path: "/blog",
    component: () => import("/app/pages/blog.vue").then(m => m.default || m),
    children: [
  {
    name: "blog-slug___cz",
    path: ":slug()",
    component: () => import("/app/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog___cz",
    path: "",
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: blogR6EtYuEVDlMeta?.name,
    path: "/en/blog",
    component: () => import("/app/pages/blog.vue").then(m => m.default || m),
    children: [
  {
    name: "blog-slug___en",
    path: ":slug()",
    component: () => import("/app/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog___en",
    path: "",
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "createProfile___cz",
    path: "/createProfile",
    meta: createProfilelTCL4xtPZjMeta || {},
    component: () => import("/app/pages/createProfile.vue").then(m => m.default || m)
  },
  {
    name: "createProfile___en",
    path: "/en/createProfile",
    meta: createProfilelTCL4xtPZjMeta || {},
    component: () => import("/app/pages/createProfile.vue").then(m => m.default || m)
  },
  {
    name: "docs___cz",
    path: "/docs",
    component: () => import("/app/pages/docs.vue").then(m => m.default || m),
    children: [
  {
    name: "docs-slug___cz",
    path: ":slug(.*)*",
    component: () => import("/app/pages/docs/[...slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "docs___en",
    path: "/en/docs",
    component: () => import("/app/pages/docs.vue").then(m => m.default || m),
    children: [
  {
    name: "docs-slug___en",
    path: ":slug(.*)*",
    component: () => import("/app/pages/docs/[...slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index___cz",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login___cz",
    path: "/login",
    meta: loginhHM0vSTW5jMeta || {},
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___en",
    path: "/en/login",
    meta: loginhHM0vSTW5jMeta || {},
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "pick___cz",
    path: "/pick",
    meta: pick7jB5p0aBxZMeta || {},
    component: () => import("/app/pages/pick.vue").then(m => m.default || m)
  },
  {
    name: "pick___en",
    path: "/en/pick",
    meta: pick7jB5p0aBxZMeta || {},
    component: () => import("/app/pages/pick.vue").then(m => m.default || m)
  },
  {
    name: "pricing___cz",
    path: "/pricing",
    component: () => import("/app/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "pricing___en",
    path: "/en/pricing",
    component: () => import("/app/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "signup___cz",
    path: "/signup",
    meta: signupezBbZGXKU6Meta || {},
    component: () => import("/app/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: "signup___en",
    path: "/en/signup",
    meta: signupezBbZGXKU6Meta || {},
    component: () => import("/app/pages/signup.vue").then(m => m.default || m)
  }
]