import { createDirectus, rest } from '@directus/sdk'
import type { CustomDirectusTypes } from '~/types/directus'

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()
  const directus = createDirectus<CustomDirectusTypes>(config.public.directus.url).with(rest())

  return {
    provide: {
      directus,
    },
  }
})
