import Stripe from 'stripe'

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()
  const stripe = new Stripe(config.public.STRIPE_SECRET_KEY)

  return {
    provide: {
      stripe,
    },
  }
})
